.slider-container {
  position: relative;
  width: 100%;
}

.slider {
  position: relative;
  display: flex;
  align-items: center;
}

.track {
  position: absolute;
  top: 0;
  left: 0;
  background: #264e73;
  height: 5px;
  z-index: -1;
}

.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #264e73;
  outline: none;
  border-radius: 0;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 15px;
  background: orange;
  cursor: pointer;
}

.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 10px;
  background: orange;
  cursor: pointer;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; /* Adjust the distance between the label and the slider */
}
