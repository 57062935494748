.macro-tool-widget {
  display: flex;
  flex-direction: row;
}

.widget-tool-side {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 30%;
}

.widget-map-side {
  height: 100vh;
  width: 70%;
  flex: 2;
}

.tool-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 70%;
  color: #264e73;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.button-holder {
  background-color: #92a6b8;
  padding: 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.tab-group {
  display: flex;
  margin-left: 8%;
  margin-right: 8%;
}

.tab {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #264e73;
  background-color: white;
  color: orange;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.input-holder {
  height: 100vmax;
  overflow: scroll;
}

.tab.active {
  background-color: #264e73;
  color: #fff;
}

.tab-content {
  display: none;
  scroll-behavior: smooth;
}

.tab-content.active {
  display: block;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.container-topper {
  background-color: #fff0df;
  padding: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: small;
}

.bordered {
  border: 1px solid black !important;
}

.test {
  display: flex;
  align-items: center;
  gap: 5px;
}

.radioSelected {
  display: flex;
  align-items: center;
  gap: 1px;
  margin-left: 10px;
}

.radioInput:checked {
  accent-color: #fd9426;
  border: 1px solid white;
}

.radioInput {
  display: inline-block;
  border: 1px solid #fd9426;
  border-radius: 50%;
  outline: none;
  transition: background-color 0.3s;
}

.select-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 5%;
  flex: 2;
}

.stateDropdown {
  border: none;
  color: grey;
  appearance: none;
  background: white;
  padding-right: 15px;
  padding-left: 12px;
  width: 100%;
}

.select-arrow {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: grey;
}

.container-inner {
  padding: 3%;
  background-color: #fffaf4;
  margin-left: 5%;
}

.tab-content#output .container-inner {
  display: flex;
  flex-direction: column;
  padding: 5%;
}

.tab-content#setting .container-inner {
  padding: 5%;
}

.slider-option {
  color: #264e73;
  margin-bottom: 5%;
}

.slider-title {
  margin-bottom: 5px;
}

.tab-content#data .orange-container {
  margin: 6% 10%;
  background-color: #fffaf4;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.tab-content#output .output-container {
  margin: 6% 10%;
  background-color: #fffaf4;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.tab-content#settings .setting-container {
  margin: 4% 10%;
  background-color: #fffaf4;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.triangle {
  color: orange;
}

.dropdownButton {
  background-color: white;
  color: orange;
  border: 1px solid black;
}
.dropdownButton:hover {
  background-color: #fff0df;
  color: orange;
  border: 1px solid black;
}

.dropdown-menu {
  display: flex;
  position: absolute;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #f9f9f9;
  justify-items: flex-start;
  align-content: flex-start;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.btn-group {
  position: relative;
}

.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  background-color: white;
  top: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.dropdown-menu.show.left {
  left: 0;
  width: 100%;
}

.dropdown-menu.show.right {
  right: 0;
  width: 150%;
}

.checkbox-input {
  margin-right: 10px;
}

.tab-content#output .output-button {
  background-color: #264e73;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: medium;
}

.checkbox-label {
  margin-left: 3%;
  font-size: smaller;
}

.gap1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selected-name-lsads-container {
  border: 1px solid #a4a4a4;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3%;
  height: 55px;
  overflow-y: auto;
  scrollbar-width: none;
  padding: 5px;
}

.selected-name-lsad {
  font-size: small;
  display: flex;
  align-items: center;
  height: 25px;
  margin-left: 2px;
  margin-right: 3px;
  margin-bottom: 2px;
  background-color: #e9edf1;
  border-radius: 25px;
  padding: 1px 5px 1px 5px;
}

.remove-name-lsad {
  background-color: transparent;
  border: none;
  color: black;
  margin-left: 5px;
  font-weight: 1000;
  cursor: pointer;
}

.ellipsis {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.popout {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid black;
  background-color: white;
  width: 282px;
  max-height: 100px;
  overflow-y: scroll;
  padding: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.selected-counties-container {
  position: relative;
}

.selected-counties-box {
  border: 1px solid black;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3%;
  height: 55px;
  max-height: 55px;
  overflow: hidden;
  padding: 2px;
}

.selected-county-name-inBOX {
  font-size: smaller;
  display: flex;
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #e9edf1;
  border-radius: 25px;
  padding: 5px;
}

.selected-county-name-inPOP {
  font-size: smaller;
  display: flex;
  margin-left: 2px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: #e9edf1;
  border-radius: 25px;
  padding: 5px;
}
.remove-county-name {
  background-color: transparent;
  border: none;
  color: orange;
  margin-left: 2px;
  margin-right: 1px;
  font-weight: 900;
  cursor: pointer;
}

.countyPopout {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid black;
  background-color: white;
  width: 282px;
  max-height: 100px;
  overflow-y: scroll;
  padding: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.dataTitleTopper {
  font-size: medium;
}

.analysis-type-checkbox {
  font-size: medium;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.analysis-type-checkbox label {
  margin-left: 2%;
  display: flex;
  align-items: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  width: 75%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.weight-sum-box {
  color: #fd9426;
  width: 50%;
  padding: 5px 0px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.accordion-button.collapsed {
  background-color: rgba(253, 148, 38, 0.15);
}

.accordion-button:not(.collapsed) {
  background-color: #fd9426;
}

.accordion-header {
  --bs-accordion-active-bg: #fd9426;
}

.accordion-button:not(.collapsed) .accordion-header {
  background-color: #fd9426;
}

.accordion-button:not(.collapsed):focus {
  background-color: #fd9426;
}

.accordion-button::after {
  color: #fd9426;
}

.modal-90w {
  --bs-modal-width: 100%;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  --bs-modal-width: 100%;
}

.carousel-control-next {
  margin: auto;
  height: 20px;
}

.carousel-control-next-icon {
  color: black;
  background-color: #264e73;
  border-radius: 10px;
}

.carousel-control-prev {
  margin: auto;
  height: 20px;
}

.carousel-control-prev-icon {
  color: black;
  background-color: #264e73;
  border-radius: 10px;
}

.carousel-indicators [data-bs-target] {
  margin-bottom: -20px;
  background-color: #fd9426 !important;
}

.carousel slide {
  opacity: 0.2;
}

.plot-table-show {
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.modal-control-icon {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  margin-left: 10px;
}

.expand-modal {
  padding: 5px;
  cursor: pointer;
  color: black;

  &:hover {
    opacity: 0.7;
    transition: 0.5s;
  }
}

.arrow-nav-icon {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.accordion-item:first-of-type {
  border-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion-item:first-of-type .accordion-button.collapsed {
  border-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion-item:last-of-type {
  border-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.results-toggle-button {
  color: black;
  border: 1px solid rgba(253, 148, 38, 0.15);
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: medium;
}

.expand-table-button:hover {
  opacity: 0.8;
  transition: 0.2s;
}
